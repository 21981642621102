<div class="invoice-page invoice" *ngIf="order">
  <div class="invoice__head">
    <img class="invoice__logo" src="/assets/custom/icons/topdrop-logo.png" />
    <p class="invoice__logo-subtext">Feinste Weine - Kleinste Preise</p>
  </div>

  <div class="invoice__addresses">
    <div>
      <strong>Rechnungsadresse</strong>
      <p>{{ order.billingAddress.fullName }}</p>
      <p>{{ order.billingAddress.streetLine1 }}</p>
      <p>{{ order.billingAddress.streetLine2 }}</p>
      <p>{{ order.billingAddress.postalCode }} {{ order.billingAddress.city }}</p>
      <p>{{ order.billingAddress.country }}</p>
    </div>

    <div>
      <strong>Lieferadresse</strong>
      <p>{{ order.shippingAddress.fullName }}</p>
      <p>{{ order.shippingAddress.streetLine1 }}</p>
      <p>{{ order.shippingAddress.streetLine2 }}</p>
      <p>{{ order.shippingAddress.postalCode }} {{ order.shippingAddress.city }}</p>
      <p>{{ order.shippingAddress.country }}</p>
    </div>

    <div class="invoice__address-right">
      <p>Topdropmarket GmbH</p>
      <p>Hirschgraben 24</p>
      <p>22089 Hamburg</p>
      <p class="invoice__address-phone">Tel. 0173 313 29 48</p>
      <p>info&#64;topdropmarket.de</p>
      <p>www.topdropmarket.de</p>
    </div>
  </div>

  <h1 class="invoice__headline">Rechnung</h1>

  <div class="invoice__order-data">
    <div>
      <div>
        <strong>Kundennummer: </strong>
        <span>{{ customerNumber }}</span>
      </div>

      <div>
        <strong>Rechnungsnummer: </strong>
        <span>{{ order.customFields.invoiceNumber }}</span>
      </div>
    </div>

    <div>
      <div>
        <strong>Bestellnummer: </strong>
        <span>{{ order.code }}</span>
      </div>

      <div>
        <strong>Transaktions-ID: </strong>
        <span>{{ order.payments[0].transactionId }}</span>
      </div>
    </div>

    <div>
      <strong>Datum: </strong>
      <span>{{ order.payments[0].createdAt | date: 'dd.MM.yyyy' }}</span>
    </div>
  </div>

  <div class="invoice__table">
    <div class="invoice__table-row">
      <strong class="invoice__table-cell--desc">Artikelbezeichnung</strong>
      <strong class="invoice__table-cell">Artikelnummer</strong>
      <strong class="invoice__table-cell">Preis</strong>
      <strong class="invoice__table-cell">Menge</strong>
      <strong class="invoice__table-cell">Summe</strong>
    </div>

    <div class="invoice__table-row" *ngFor="let line of order.lines">
      <span class="invoice__table-cell--desc">{{ line.productVariant.name }}</span>
      <span class="invoice__table-cell">{{ line.productVariant.sku }}</span>
      <span class="invoice__table-cell">{{ line.unitPriceWithTax / 100 | currency:"EUR" }}</span>
      <span class="invoice__table-cell">{{ line.quantity }}</span>
      <span class="invoice__table-cell">{{ line.linePriceWithTax / 100 | currency:"EUR" }}</span>
    </div>

    <div class="invoice__table-row invoice__discount-row" *ngFor="let coupon of coupons;">
      <span class="invoice__table-cell--desc">Gutschein eingelöst: {{ coupon.code }}</span>
      <span class="invoice__table-cell"> </span>
      <span class="invoice__table-cell"> </span>
      <span class="invoice__table-cell"> </span>
      <span class="invoice__table-cell">- {{ coupon.amountWithTax / -100 | currency:"EUR" }}</span>
    </div>

    <div class="invoice__table-row invoice__discount-row" *ngFor="let surcharge of surcharges;">
      <span class="invoice__table-cell--desc">{{ surcharge.reason || "Preisanpassung" }}</span>
      <span class="invoice__table-cell"> </span>
      <span class="invoice__table-cell"> </span>
      <span class="invoice__table-cell"> </span>
      <span class="invoice__table-cell">
        {{ surcharge.amountWithTax < 0 ? "-" : "" }}
        {{ abs(surcharge.amountWithTax) / 100 | currency:"EUR" }}
      </span>
    </div>
  </div>

  <div class="invoice__summary-row">
    <strong>Versand: </strong>
    <span class="invoice__summary-price">{{ order.shippingWithTax / 100 | currency:"EUR" }}</span>
  </div>

  <div class="invoice__summary-row">
    <strong>Enthaltene MwSt. 19%: </strong>
    <span class="invoice__summary-price">{{ (order.totalWithTax - order.total) / 100 | currency:"EUR" }}</span>
  </div>

  <div class="invoice__summary-row">
    <strong>Fälliger Betrag: </strong>
    <strong class="invoice__summary-price">{{ order.totalWithTax / 100 | currency:"EUR" }}</strong>
  </div>

  <br />

  <p>
    Wir liefern und berechnen die angeführten Waren zu unseren Allgemeinen Geschäftsbedingungen. Bezüglich der
    Entgeltminderung verweisen wir auf unsere aktuellen Zahlungs- und Konditionsvereinbarungen. Das Rechnungsdatum
    entspricht dem Lieferdatum.
  </p>

  <br />

  <p>Die gelieferte Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.</p>

  <div class="invoice__footer">
    <div>
      <p>topdropmarket GmbH</p>
      <p>Hirschgraben 24</p>
      <p>22089 Hamburg</p>
    </div>

    <div>
      <p>IBAN: DE70 2005 0550 1261 1870 49</p>
      <p>BIC: HASP DEHH XXX</p>
      <p>Hamburger Sparkasse</p>
    </div>

    <div></div>

    <div>
      <p>USt-IdNr.: DE290397170</p>
      <p>AG Hamburg HRB 127969</p>
    </div>
  </div>

</div>
